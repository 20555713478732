import $ from 'jquery-slim';
import Slider from 'bootstrap-slider';

class OptSlider
{
    constructor()
    {
        $(document).ready(this.init.bind(this));
    }

    init()
    {
        Slider;

        let sliders = $('.slider');
        let slider;
        let config;

        for (let i = 0; i < sliders.length; i++)
        {
            slider = sliders.eq(i);
            config = {};

            switch(true)
            {
                case slider.hasClass('slider-price'):
                    config.formatter = this.formatPrice.bind(this);
                    break;

            }

            /**
             * use of bootstrapSlider instead of slider because of conflict with jquery-ui
             * see https://github.com/seiyria/bootstrap-slider#using-bootstrapslider-namespace
             */
            slider.bootstrapSlider(config);
        }

    }

    formatPrice(value)
    {
        switch (true)
        {
            case Array.isArray(value):
                return value.join(' - ') + ' €';

        }
        return value + + ' €';
    }

}

const slider = new OptSlider();






